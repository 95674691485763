'use strict'

export default function (): void {
  const formEl = document.querySelector('.login-form') as HTMLFormElement
  const loginEl = document.getElementById('login_username') as HTMLInputElement

  if (!loginEl) {
    return
  }

  const passwordEl = document.getElementById('login_password') as HTMLInputElement
  const submitEl = document.getElementById('login_validate') as HTMLInputElement

  loginEl.blur()

  if (!loginEl.checkValidity() && !passwordEl.checkValidity()) {
    formEl.classList.add('login-form--invalid')
  }

  loginEl.addEventListener('keyup', function () {
    if (this.checkValidity() && passwordEl.checkValidity()) {
      formEl.classList.remove('login-form--invalid')
    }
  })

  passwordEl.addEventListener('keyup', function () {
    if (this.checkValidity() && loginEl.checkValidity()) {
      formEl.classList.remove('login-form--invalid')
    }
  })

  formEl.addEventListener('submit', function () {
    submitEl.innerText = submitEl.getAttribute('data-loading-message') as string
  })
}
