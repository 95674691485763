'use strict'

import form from 'assets/themes/ce/js/page/login/form'
import map from 'assets/themes/ce/js/page/homepage/map'
import bookingCancelledModal from 'assets/themes/ce/js/page/product/bookingCancelledModal'

document.addEventListener('DOMContentLoaded', function () {
  const currentPageType = document.body.getAttribute('data-pagetype') as string

  if (/(login-b2b|login-b2c)/.test(currentPageType)) {
    form()
  }

  if (/homepage/.test(currentPageType)) {
    map()
  }

  if (/product/.test(currentPageType)) {
    bookingCancelledModal()
  }
})
