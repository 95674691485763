'use strict'

import Modal from 'assets/core/js/module/modal'

export default function (): void {
  const bookingCancelledEl = document.getElementById('booking-cancelled')

  if (bookingCancelledEl) {
    const modal = new Modal(bookingCancelledEl, {
      class: 'booking-cancelled-modal',
    })

    modal.open()
  }
}
