'use strict'

export default function (): void {
  const useElement = document.querySelector('#map-front') as HTMLElement

  document.querySelectorAll('.cms-map svg a').forEach((link) =>
    link.addEventListener('mouseenter', () => {
      useElement.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', '#' + link.id)
    })
  )
}
